<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
				<h5 style="font-weight: 600;"> User Types ({{userTypeList.total}})</h5>
			</CCol>

			<CCol lg="6">
				<CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('user_types').create"
						size="sm" style="margin-right: 10px" 
						color="success" @click="toggleModal()" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add User Type
					</CButton>
		      		
		    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @usertype-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>
		<CRow>
			
	      <CCol lg="12" class="text-center" v-if="isLoading"> 
				  <div class="spinner-border m-5" role="status">
						<span class="sr-only">Loading...</span>
					</div>
			  </CCol>
		      <CCol lg="12" v-else>  
				  <div class="table-responsive-sm table-responsive-md">
	      	
			    	<CDataTable
				      :items="userTypeList.data"
				      :fields="fields"
					     hover
         				  striped
						  outlined
						  small
						  fixed
						 
				    >  
				      	<template #action="{item}">
					        <td style="padding: 5px; width: 100px">
					        	<CDropdown 
					               toggler-text="Select"  
									size="sm"
					                color="info"
					            >
					                 <CDropdownItem v-if="config.getPermission('user_types').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						            <CDropdownItem v-if="config.getPermission('user_types').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem> 
					                <CDropdownItem @click="togglePermissionModal(item.id)"><i class="fa fa-pencil-square-o"> Set Permission</i></CDropdownItem>
					            </CDropdown>
					        </td>
				      	</template>
				    </CDataTable> 
				    <CPagination
			          	:active-page.sync="currentPage"
			          	:pages="Math.ceil(userTypeList.total / 20)"
			          	:activePage="currentPage"
			          	@update:activePage="updatePage"
			        />
				  </div>
	      	
	      </CCol>
	    </CRow>
	    <CModal title="Add User type" :show.sync="formModal" color="info">
           	<form @submit.prevent="submit">
           		<CRow>
					<CCol lg="12">
							<label>User Type <span class="text-danger">*</span></label>
						<CInput
		                 
		                  placeholder="Enter user type"
		                  v-model="userTypeName"
		                  required="true"
		                />
					</CCol>
				</CRow>
	            <div slot="footer" class="w-100">
	            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	                <CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Okay
	                </CButton>
	                
	            </div>
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>
        <CModal size="lg" title="Set Permission" :show.sync="permissionModal" color="info">
           	<form @submit.prevent="">
           		<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Bookings
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="test" 
				                    :inline="true" 
			                    /> 
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Print'"
									:checked.sync="test" 
				                    :inline="true" 
			                    /> 
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Deliver'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Complete'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Dispatch'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Approve'"
									:checked.sync="test" 
				                    :inline="true" 
			                    /> 
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Cancel'"
									:checked.sync="test" 
				                    :inline="true" 
			                    /> 
							</CCol>
						</CRow>
					</CCol>
				</CRow>
				<br/>


				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Client Billing
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="test" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						</CRow>
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>


				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Vehicles
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="test" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						</CRow>
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>


				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Incidents
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="test" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						</CRow>
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="test" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
	            <div slot="footer" class="w-100">
	            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="permissionModal = false">
	                    Cancel
	                </CButton>
	                <CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Okay
	                </CButton>
	                
	            </div>
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script>
import CTableWrapper from '../../base/Table.vue'
import usersData from '../../users/UsersData'
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'

export default {
	mounted(){
		console.log(config);
		this.getUserType();
	},
	data(){
		return{
			config,
			formModal: false,
			permissionModal: false,
			userTypeList: [],
			userTypeName: "",
			fields: [
				{
					key: 'setting_name', 
					label: 'Usertype'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,
			editMode: false,
			selectedRow: {},
			filterName: "",
			showCollapse: false,
			isLoading: false,
			test: ""
		}
	},
	name: 'Tables',
	components: { CTableWrapper, Search },
	methods: {
		updatePage(data){
			this.currentPage = data;
			this.getUserType();
		},
	    shuffleArray (array) {
	      for (let i = array.length - 1; i > 0; i--) {
	        let j = Math.floor(Math.random() * (i + 1))
	        let temp = array[i]
	        array[i] = array[j]
	        array[j] = temp
	      }
	      return array
	    },

	    getShuffledUsersData () {
	      return this.shuffleArray(usersData.slice(0))
	    },

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    },

	    togglePermissionModal(id){
	    	window.open(
			  '/#/settings/permission/'+id,
			  '_blank' // <- This is what makes it open in a new window.
			); 
	    },

	    submit(){
	    	var ax = {};
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/setting/"+this.selectedRow.id, {
		    		setting_type: "user_type",
		    		setting_name: this.userTypeName
		    	})
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/setting", {
		    		setting_type: "user_type",
		    		setting_name: this.userTypeName
		    	})
	    	}
	    	ax.then(response => {
	    		var text = 'User type successfully added!';
	    		if(this.editMode){
	    			text = 'User type successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.userTypeName = ""
	    		this.getUserType();
	    	})
	    },
		search(event){
	    	this.filterName = event.name;
	    	this.getUserType();
	    },

	    getUserType(){
		this.isLoading=true;
	    	axios.get(config.api_path+'/setting?setting_type=user_type&setting_name='+this.filterName+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.userTypeList = response.data;
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})

	    },

	    updateModal(item){

	    	this.selectedRow = item;
	    	this.userTypeName = item.setting_name;
	    	this.formModal = true;
	    	this.editMode = true;

	    },

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this user type?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/setting/'+item.id)
			    	.then(response => {
			    		this.getUserType(); 
			    		Swal.fire({
							title: 'Success!',
							text: "User type successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    }

 	}
}
</script>
